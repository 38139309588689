<template>
  <div>
    <common-header></common-header>
    <div class="payment-box" v-if="payStatus">
      <div class="pay-txt">
        <i :class="payStatus.statusDesc == '未支付' ? 'icon-pay-error' : 'icon-pay-success' "></i>{{payStatus.statusDesc == '未支付' ? '支付失败' : '支付成功'}}
      </div>
      <div class="money">实付款￥{{payStatus.payAmount ? payStatus.payAmount : price}}</div>
      <div class="btn-box">
        <router-link :to="`/account/orderdetails?id=${orderCode}&isId=false`" class="btn ">查看订单</router-link>
        <router-link to="/" class="btn jb-r-l">再去逛逛</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      payStatus: "",
      price: "",
      orderCode: "",
    }
  },
  mounted() {
    this.price = this.$route.query.price;
    this.orderCode = this.$route.query.orderCode;
    this.getOrderPayType({
      orderCode: this.orderCode,
    }).then((res)=> {
      this.payStatus = res.data;
    })
  },
  methods: {
    ...mapActions('shopping', [
      "getOrderPayType",
    ])
  },
}
</script>
<style lang='less' scoped>
.btn-box{
  display: flex;
  margin-top: 1.1rem;
  justify-content: center;
  .btn{
    font-size: .32rem;
    color: #ff7241;
    width: 2.5rem;
    height: .78rem;
    line-height: .78rem;
    text-align: center;
    border-radius: .4rem;
    border: 1px solid #ff7241;
    margin: 0 .18rem;
    &.jb-r-l{
      border: none;
      color: #fff;
    }
  }
}
.payment-box{
  background: #fff;
  padding-top: 1.11rem;
  padding-bottom: .48rem;
}
.money{
  font-size: .3rem;
  color: #262626;
  text-align: center;
  margin-top: .3rem;
}
.pay-txt{
  display: flex;
  align-items: center;
  color: #262626;
  font-weight: bold;
  justify-content: center;
  .icon-pay-success,.icon-pay-error{
    margin-right: .2rem;
  }
}
</style>